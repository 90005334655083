.content {
    width: 680px;
    margin: 10px;
    padding: 10px;
    background-color: white;
  }
  .body {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .logo {
    width: 100px;
    height: 100px;
    margin: auto 0;
  }
  .img {
    width: 90px;
    height: 90px;
    margin-top: -10px;
  }
  .header {
    display: flex;
  }
  .heading {
    width: 600px;
  }
  @font-face {
    font-family: "swathi2";
    src: url("../FontFiles/browab_0.ttf");
  }
  .addresses {
    margin-top: -22px;
  }
  .address {
    font-family: "swathi2";
    font-size: 24px;
    /* font-weight: bold; */
    float: right;
    margin-top: -30px;
    margin-right: 5px;
  }
  .address2 {
    font-family: "swathi2";
    font-size: 24px;
    /* font-weight: bold; */
    float: right;
    margin-top: -12px;
    margin-right: 5px;
  }
  
  @font-face {
    font-family: "swathi";
    src: url("../FontFiles/unicode.revueb.ttf");
  }
 
  .main {
    color: red;
    font-family: "swathi";
    font-size: 38px;
    margin-top: -7px;
    text-align: center;
  }
  @font-face {
    font-family: "swathi1";
    src: url("../FontFiles/ITC-Zapf-Chancery-Bold.ttf");
  }
  .subMain {
    margin-right: 20px;
    text-transform: capitalize;
    font-size: 20px;
    text-align: right;
    font-family: "swathi1";
    margin-top: -30px;
  }
  .line {
    margin-top: -10px;
    border: 2px solid black;
  }
  .date {
    display: flex;
    font-size: 13px;
    justify-content: flex-end;
  }
  .date input {
    border: none;
    margin-top: -4px;
    width: 100px;
    border-bottom: 2px dotted black;
    margin-right: 30px;
  }
  .date label {
    margin-top: -4px;
    font-family: "swathi3";
  }
  .subHead {
    font-size: 15px;
    text-decoration: underline;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    margin-top: -8px;
    font-family: "swathi3";
  }
  @font-face {
    font-family: "swathi3";
    src: url("../FontFiles/times-new-roman-grassetto.ttf");
  }
  
  .tableOne th {
    width: 300px;
    font-size: 13px;
    font-family: "swathi3";
    text-align: left;
    padding: 2px;
  }
  .tableOne td {
    width: auto;
    padding: 2px;
  }
  .tableTwo {
    margin-top: -28px;
    border: 1px solid black;
  }
  .tableTwo th {
    font-size: 12px;
    text-align: center;
    font-family: "swathi3";
    border: 1px solid black;
  }
  .tableTwo td {
    border: 1px solid black;
  }
  .tableTwo td input {
    border: none;
    width: 100%;
    padding: 0px;
  }
  h6 {
    font-size: 13px;
    font-family: "swathi3";
    margin-top: -1px;
    margin-top: 6px;
  }
  .check {
    font-family: "swathi3";
    text-transform: uppercase;
    font-size: 14px;
    margin-top: -27px;
    margin-left: -px;
  }
  
  .sign {
    display: flex;
    justify-content: space-between;
    margin-top: -20px;
    font-size: 13px;
    flex-direction: column;
    text-transform: uppercase;
    font-family: "swathi3";
  }
  .inSign {
    display: flex;
    justify-content: space-between;
  }
  .count {
    display: flex;
    justify-content: flex-start;
    gap: 100px;
  }
  .check input {
    margin-left: 3px;
  }
  input[type="checkbox"]::before {
    content: "";
    display: block;
    width: 35px;
    height: 20px;
    background-color: white;
    border: 1px solid black;
  }
  input[type="checkbox"]:checked::before {
    content: "✓";
    color: #7a69cd;
    text-align: center;
  }
  
  label {
    margin-left: 45px;
  }
  .inputField {
    border: none;
    border-bottom: 1px solid black;
    width: 248%;
  }
  input {
    outline: none;
  }
  .semiColon {
    width: auto;
  }
  input[type="checkbox"] {
    border-radius: 0;
    border: 2px solid black;
    padding: 20px;
  }
  .inputTwo {
    border: none;
    width: auto;
  }
  .inputThree {
    border: none;
    border-bottom: 1px solid black;
    width: 100%;
    height: 13px;
    font-size: 13px;
  }
  .refer13 {
    margin-top: -20px;
  }